import { useMemo, useState } from 'react';
import { useLinkParams } from '../use-query-params';
import { DateRange } from './activity-side-menu-filters';
import { useReportTypeStore } from 'shared/state/reporting/reportTypeStore';
import { REPORT_TYPE } from 'reporting/constants';
import { useGetAllTagsOfTagType } from '../../api';
import { DAY_CODE_TAG_TYPE } from '../constants';

interface SideMenuFilterState {
    textFilter: string;
    setTextFilter: (filter: string) => void;
    dateFilters: DateRange;
    setDateFilters: (dates: DateRange) => void;
    tagFilters: string[];
    setTagFilters: (tagFilters: string[]) => void;
}
export const useSideMenuFilterState = (): SideMenuFilterState => {
    const { search, dateFrom, dateTo, tags } = useLinkParams();
    const { reportType } = useReportTypeStore();
    const dayCodeTagResponse = useGetAllTagsOfTagType(DAY_CODE_TAG_TYPE); // should be preloaded and fetched from cache

    // here
    const initialTags = useMemo(() => {
        if (reportType === REPORT_TYPE.match_day && !dayCodeTagResponse.isLoading && dayCodeTagResponse.data) {
            return dayCodeTagResponse.data.filter((t) => t.name === 'MD').map((t) => t.name ?? '');
        } else {
            return tags;
        }
    }, [reportType, dayCodeTagResponse.isLoading, dayCodeTagResponse.data, tags]);

    // Local states
    const [textFilter, setTextFilter] = useState<string>(search ?? '');
    const [dateFilters, setDateFilters] = useState<DateRange>({ from: dateFrom, to: dateTo } as unknown as DateRange);
    const [tagFilters, setTagFilters] = useState<string[]>(initialTags);

    return { textFilter, setTextFilter, dateFilters, setDateFilters, tagFilters, setTagFilters };
};
