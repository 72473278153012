const REPORTING_PREFIX = 'Web_Report_';
const SIDE_MENU_PREFIX = 'Web_SideMenu_';
const EDITOR_PREFIX = 'Web_Editor_';
const FOCUS_PREFIX = 'Web_FocusImport_';

export const ACTIONS = {
    loaded: 'Page Loaded',
    unloaded: 'Page Unloaded',
    iconClicked: 'Icon Clicked',
    buttonClicked: 'Button Clicked',
    selected: 'Selected',
    deselected: 'Deselected',
    expanded: 'Expanded',
    opened: 'Opened',
    closed: 'Closed',
};

export const EVENTS = {
    sideMenu: {
        activitySearch: SIDE_MENU_PREFIX + 'ActivitySearch',
        activitySelection: SIDE_MENU_PREFIX + 'ActivitySelection',
    },
    reporting: {
        reportOpened: REPORTING_PREFIX + 'ReportOpened',
        reportClosed: REPORTING_PREFIX + 'ReportClosed',
        tabChanged: REPORTING_PREFIX + 'TabChanged',
        activityNameEditStarted: REPORTING_PREFIX + 'ActivityNameEditStarted',
        activityNameEditSubmitted: REPORTING_PREFIX + 'ActivityNameEditSubmitted',
        activityNameEditSuccess: REPORTING_PREFIX + 'ActivityNameEditSuccess',
        activityNameEditFailure: REPORTING_PREFIX + 'ActivityNameEditFailure',
        athleteParticipationGroupViewed: REPORTING_PREFIX + 'AthleteParticipationGroupViewed',
        reportExport: REPORTING_PREFIX + 'ReportExport',
        reportToEditActivity: REPORTING_PREFIX + 'ReportToEditActivity',
        periodInsights: REPORTING_PREFIX + 'PeriodInsights',
        athleteInsights: REPORTING_PREFIX + 'AthleteInsights',
        compareToFilter: REPORTING_PREFIX + 'CompareToFilter',
        filter: REPORTING_PREFIX + 'Filter',
        periodSortView: REPORTING_PREFIX + 'PeriodSortView',
        periodSort: REPORTING_PREFIX + 'PeriodSort',
        athleteChartSortView: REPORTING_PREFIX + 'AthleteChartSortView',
        athleteChartSort: REPORTING_PREFIX + 'AthleteChartSort',
        athleteChartFilterView: REPORTING_PREFIX + 'AthleteChartFilterView',
        athleteChartFilter: REPORTING_PREFIX + 'AthleteChartFilter',
        parameterUpdate: REPORTING_PREFIX + 'ParameterUpdate',
        reportTypeSelection: REPORTING_PREFIX + 'ReportTypeSelection',
        athletePeriodChartSortView: REPORTING_PREFIX + 'AthletePeriodChartSortView',
        athletePeriodChartSort: REPORTING_PREFIX + 'AthletePeriodChartSort',
        athletePeriodChartFilterView: REPORTING_PREFIX + 'AthletePeriodChartFilterView',
        athletePeriodChartFilter: REPORTING_PREFIX + 'AthletePeriodChartFilter',
        dailyBreakdownChartSort: REPORTING_PREFIX + 'DailyBreakdownChartSort',
        dailyBreakdownChartSortView: REPORTING_PREFIX + 'DailBreakdownChartSortView',
        dailyBreakdownChartFilterView: REPORTING_PREFIX + 'DailyBreakdownChartFilterView',
        tagFilter: REPORTING_PREFIX + 'TagFilter',
        heatMapSelected: REPORTING_PREFIX + 'HeatMapSelected',
        traceMapSelected: REPORTING_PREFIX + 'TraceMapSelected',
        venueCreated: REPORTING_PREFIX + 'VenueCreated',
        dateSelected: REPORTING_PREFIX + 'DateSelected',
        performanceFlagSidePanel: REPORTING_PREFIX + 'PerformanceFlagSidePanel',
        performanceFlag: REPORTING_PREFIX + 'PerformanceFlag',
        performanceFlagNameChanged: REPORTING_PREFIX + 'PerformanceFlagNameChanged',
        performanceFlagCreated: REPORTING_PREFIX + 'PerformanceFlagCreated',
        performanceFlagDeleted: REPORTING_PREFIX + 'PerformanceFlagDeleted',
        performanceFlags: REPORTING_PREFIX + 'PerformanceFlags',
        performanceFlagReordered: REPORTING_PREFIX + 'PerformanceFlagReordered',
        performanceFlagTypeChanged: REPORTING_PREFIX + 'PerformanceFlagTypeChanged',
        performanceFlagAttributeChanged: REPORTING_PREFIX + 'PerformanceFlagAttributeChanged',
        intervalChanged: REPORTING_PREFIX + 'AnnotationChartIntervalChanged',
        matchdayAthleteChartSortView: REPORTING_PREFIX + 'MatchdayAthleteChartSortView',
        matchdayAthleteChartSort: REPORTING_PREFIX + 'MatchdayAthleteChartSort',
        matchdayAthleteChartFilterView: REPORTING_PREFIX + 'MatchdayAthleteChartFilterView',
        matchdayAthleteChartFilter: REPORTING_PREFIX + 'MatchdayAthleteChartFilter',
    },
    editing: {
        periodCreated: EDITOR_PREFIX + 'PeriodCreated',
        periodEdit: EDITOR_PREFIX + 'PeriodEdited',
        periodDelete: EDITOR_PREFIX + 'PeriodDeleted',
        deviceUnmapped: EDITOR_PREFIX + 'DeviceUnmapped',
        deviceMapped: EDITOR_PREFIX + 'DeviceMapped',
        dayCodeTagChanged: EDITOR_PREFIX + 'DayCodeTagChanged',
        participationTagChanged: EDITOR_PREFIX + 'ParticipationTagChanged',
        graphToolSelectionChanged: EDITOR_PREFIX + 'GraphToolSelectionChanged',
        graphRendered: EDITOR_PREFIX + 'GraphRendered',
        zoomToPeriod: EDITOR_PREFIX + 'ZoomToPeriod',
        zoomReset: EDITOR_PREFIX + 'ZoomReset',
        zoomOnSelection: EDITOR_PREFIX + 'ZoomOnSelection',
        zoomOnClick: EDITOR_PREFIX + 'ZoomOnClick',
        zoomOnPinch: EDITOR_PREFIX + 'ZoomOnPinch',
        webEditorViewSelected: EDITOR_PREFIX + 'WebEditorViewSelected',
        athletesAdded: EDITOR_PREFIX + 'Athletes_Added',
        athletesRemoved: EDITOR_PREFIX + 'Athletes_Removed',
        selectAllPeriods: EDITOR_PREFIX + 'SelectAllPeriods',
        rotationCreated: EDITOR_PREFIX + 'RotationCreated',
        rotationAdjusted: EDITOR_PREFIX + 'RotationAdjusted',
        rotationMerged: EDITOR_PREFIX + 'RotationMerged',
        annotationDelete: EDITOR_PREFIX + 'AnnotationDeleted',
        sortAthletes: EDITOR_PREFIX + 'SortAthletes',
    },
    focus: {
        startUpPageModal: FOCUS_PREFIX + 'StartUpPageModal',
        startUpPageModalImportOptionChanged: FOCUS_PREFIX + 'startUpPageModalImportOptionChanged',
        startUpPageModalStarted: FOCUS_PREFIX + 'StartUpPageModalStarted',
        startUpPageModalEnded: FOCUS_PREFIX + 'StartUpPageModalEnded',
        alignPeriod: FOCUS_PREFIX + 'AlignPeriod',
        alignPeriodOptionChanged: FOCUS_PREFIX + 'AlignPeriodOptionChanged',
        alignPeriodStarted: FOCUS_PREFIX + 'AlignPeriodStarted',
        alignPeriodEnded: FOCUS_PREFIX + 'AlignPeriodEnded',
        tagImport: FOCUS_PREFIX + 'TagImport',
        tagImportAliasUpdated: FOCUS_PREFIX + 'TagImportAliasUpdated',
        tagImportAliasSelectionMade: FOCUS_PREFIX + 'TagImportAliasSelectionMade',
        tagImportStarted: FOCUS_PREFIX + 'TagImportStarted',
        tagImportEnded: FOCUS_PREFIX + 'TagImportEnded',
        athleteParticipation: FOCUS_PREFIX + 'AthleteParticipation',
        athleteParticipationStarted: FOCUS_PREFIX + 'AthleteParticipationStarted',
        athleteParticipationEnded: FOCUS_PREFIX + 'AthleteParticipationEnded',
        athleteParticipationAssignOption: FOCUS_PREFIX + 'AthleteParticipationAssignOption',
        athleteParticipationTypeSelected: FOCUS_PREFIX + 'AthleteParticipationTypeSelected',
        athleteParticipationByTagTypeSelection: FOCUS_PREFIX + 'athleteParticipationByTagTypeSelection',
        athleteParticipationByLabelSelection: FOCUS_PREFIX + 'athleteParticipationByLabelSelection',
        mapAthletes: FOCUS_PREFIX + 'MapAthletes',
        mapAthletesEnded: FOCUS_PREFIX + 'MapAthletesEnded',
        importSubmitted: FOCUS_PREFIX + 'ImportSubmitted',
    },
};
