import * as React from 'react';
import { Select, SelectOption, ThemeProvider } from '@SBGSports/referee-react';
import { REPORT_TYPE } from '../constants';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { EVENTS } from '../../analytics/analytics-events';
import { useHasFeature } from 'api';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useModifySearchParameters } from 'reporting/hooks/url-manipulation';
import { useReportTypeStore } from 'shared/state/reporting/reportTypeStore';
import { FrontendConfigFeaturesEnum } from 'oas';
import { DATE_FROM_PARAM, DATE_TO_PARAM } from 'shared/use-query-params';

interface ReportSelectorProps {
    isActive?: boolean;
}

export const ReportSelector: React.FC<ReportSelectorProps> = (props) => {
    const { isActive } = props;

    const { activityId } = useParams<{ type: string; activityId: string }>();
    const { __ } = useTranslations();
    const history = useHistory();
    const modifySearchParams = useModifySearchParameters();

    const proReporting = useHasFeature(FrontendConfigFeaturesEnum.ProReporting);

    const { reportType, setReportType } = useReportTypeStore();

    useEffect(() => {
        mixpanel?.time_event(EVENTS.reporting.reportTypeSelection);
        return () => {
            mixpanel?.track(EVENTS.reporting.reportTypeSelection, { reportType });
        };
    }, [reportType]);

    const reportOptions: SelectOption[] = useMemo(() => {
        const reports = [
            {
                id: REPORT_TYPE.activity,
                value: __('misc.activity'),
                isSelected: reportType === REPORT_TYPE.activity,
            },
            {
                id: REPORT_TYPE.athlete,
                value: __('reporting.athlete_in_activity'),
                isSelected: reportType === REPORT_TYPE.athlete,
            },
            {
                id: REPORT_TYPE.longitudinal,
                value: __('reporting.longitudinal'),
                isSelected: reportType === REPORT_TYPE.longitudinal,
            },
            {
                id: REPORT_TYPE.match_day,
                value: __('reporting.match_day'),
                isSelected: reportType === REPORT_TYPE.match_day,
            },
        ];

        if (proReporting) {
            //add status to top of report selector list
            reports.unshift({
                id: REPORT_TYPE.status,
                value: __('reporting.status'),
                isSelected: reportType === REPORT_TYPE.status,
            });
        }

        return reports;
    }, [__, reportType, proReporting]);

    const reportRoot = '/reporting';

    const handleReportSelected = useCallback(
        (selectedOptions: SelectOption[]) => {
            const selected: string = selectedOptions?.map((selectedOption) => selectedOption.id?.toString() ?? '')?.[0];

            if (setReportType) {
                let url = `${reportRoot}/${selected}`;
                if (selected !== REPORT_TYPE.longitudinal && activityId) {
                    url = url.concat('/' + activityId);
                    if (selected === REPORT_TYPE.athlete) {
                        url = url.concat('/athlete');
                    }
                }
                history.push(url);
                setReportType(selected);
                if (selected === REPORT_TYPE.status || selected === REPORT_TYPE.longitudinal) {
                    modifySearchParams(DATE_TO_PARAM, undefined);
                    modifySearchParams(DATE_FROM_PARAM, undefined);
                    modifySearchParams('tags', undefined);
                }
            }
        },
        [activityId, history, modifySearchParams, setReportType],
    );

    const defaultOption = useMemo(() => {
        return reportOptions.find((option) => option.id === reportType);
    }, [reportOptions, reportType]);

    return (
        <ThemeProvider theme={'dark'}>
            <Select
                testId={'report-type-filter'}
                menuItems={reportOptions}
                defaultOption={defaultOption}
                onSelectItem={handleReportSelected}
                inputValueFormat={() => {
                    return __('reporting.reporting');
                }}
                style={{
                    width: '100%',
                    fontFamily: 'Mark Pro',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: isActive ? 'bold' : 400,
                    letterSpacing: 0,
                    marginRight: isActive ? '-36px' : '-28px',
                    display: 'flex',
                    marginLeft: '9px',
                    paddingRight: '22px',
                }}
                size="small"
                hideSelectedCheckmark={!isActive}
                isBorderless
            />
        </ThemeProvider>
    );
};
